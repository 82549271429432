<template>
  <div class="AppErrorPage">
    <div class="AppErrorPage__inner container">
      <h1 class="AppErrorPage__404">404</h1>
      <app-title class="AppErrorPage__title title--small title--montserrat"
        >{{getSortedTexts[0]['text_' + selectedLang]}}</app-title
      >
      <router-link to="/">
        <app-button class="button--fill">{{text1['text_' + selectedLang]}}</app-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import AppButton from '../components/AppButton.vue';
import AppTitle  from '../components/AppTitle.vue';
import {mapGetters} from "vuex";
export default {
  name: '',
  components: { AppTitle, AppButton },
  data() {
    return {
      text1: {
        title_ru: 'Перейти на главную',
        title_ua: 'Перейти на головну',
        title_en: 'Go to Main page',
        title_pl: 'Przejdź do strony głównej',
      }
    };
  },
  computed: {
    ...mapGetters(['getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },
};
</script>

<style lang="scss">
.AppErrorPage {
  @include flex(center, center, row, nowrap);
  min-height: calc(100vh);
  margin-top: -135px;
  padding-top: 240px;
  padding-bottom: 50px;
  position: relative;

  @include media-d-m($screen-netbook) {
    margin-bottom: 0;
    padding-top: 135px;
  }

  &:after {
    content: '';
    position: absolute;
    @include position();
    background-image: url('../../../assets/images/error-page/error-page__bg.png');
    opacity: 0.2;
    background-position: center top;
    margin-left: -700px;
    background-repeat: no-repeat;
    z-index: 2;
    background-size: cover;

  }

  &__inner {
    @include flex(flex-start, flex-start, column, nowrap);
    position: relative;
    z-index: 3;
  }

  &__404 {
    @include text($h68, 500, $light-yellow, $f-montserrat);
  }

  &__title {
    margin-bottom: 118px;
  }
}
</style>
